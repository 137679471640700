import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Navigation from '~/components/Navigation'
import ProductThumbnail from '~/components/ProductThumbnail'

import SEO from '~/components/seo'

const ProductPage = ({ data }) => {
  const product = data.shopifyProduct
  return (
    <Fragment>
      <SEO title={product.title} description={product.description} />
      <Navigation />
      <ProductThumbnail key={product.id} product={product} />
    </Fragment>
  )
}

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 910) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`

export default ProductPage
